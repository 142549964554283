const pdfMake = require('pdfmake/build/pdfmake.js');
const pdfFonts = require('pdfmake/build/vfs_fonts.js');
const CanvasDraw = require('../Utils/CanvasDraw');
const store = require('../store/index');
pdfMake.vfs = pdfFonts.pdfMake.vfs;
const moment = require('moment');
const { url } = require('../Utils/Url')

// steps para gerar o pdf
let steps = 1;
let sAtual = 0;

// busca as imagens no servidor e converte para base64
function urlContentToDataUri(url) {
  const fetchAsBlob = url => fetch(url)
    .then(response => response.blob());

  const convertBlobToBase64 = blob => new Promise((resolve, reject) => {
    const reader = new FileReader;
    reader.onerror = reject;
    reader.onload = () => {
      resolve(reader.result);
    };
    reader.readAsDataURL(blob);
  });
  return new Promise((resolve, reject) => {
    fetchAsBlob(url)
      .then(convertBlobToBase64)
      .then((r) => {
        resolve(r)
      }).catch((rej) => {
        reject(rej)
      });
  });
}

// ajusta as colunas para o tamanho da tabela
const collunsLength = (body) => {
  let bodyLength = body[0].length
  let width = []
  for (let index = 0; index < bodyLength; index++) {
    width.push('auto')
  }
  width[width.length - 1] = '*'
  return width;
}

const pdf = async (body, title, subTitle, subTitleAling, filename) => {
  // subtitulo opcional
  let optionSubTitle = subTitle
  let optionSubTitleAling = subTitleAling ? subTitleAling : "left"
  // carregando o logo da STV
  let lg64 = await urlContentToDataUri("./img/MASTER100px.png" + store.default.getters.getImgTokenQuery);
  // if (process.env.NODE_ENV === 'production') {
  //   lg64 = await urlContentToDataUri("./images/MASTER100px.png" + store.default.getters.getImgTokenQuery);
  // } else {
  //   lg64 = await urlContentToDataUri("./images/MASTER100px.png" + store.default.getters.getImgTokenQuery);
  // }
  // caso não venha a imagem do logo da STV seto como null
  // if (lg64) {
  //   lg64 = lg64.includes('data:image/png;base64,') ? lg64 : null;
  // }
  // dados para montar a pagina
  var dd = {
    pageOrientation: 'landscape',
    footer: function (currentPage, pageCount) { return { text: currentPage.toString() + ' de ' + pageCount, alignment: 'center' } },
    content: [{
      columns: [
        lg64 ? { image: lg64, width: 50 } : {},
        {
          text: `\n${title}`,
          style: 'header',
          alignment: 'center'

        },
      ],
    },
    { text: optionSubTitle, alignment: optionSubTitleAling },
    {
      style: 'tableExample',
      table: {
        headerRows: 1,

        widths: collunsLength(body),
        body: [
          //recebe os dados da tabela
          ...body
        ]
      },
      layout: 'lightHorizontalLines'
    },
    ],
    styles: {
      header: {
        fontSize: 18,
        bold: true,
        margin: [0, 0, 0, 10]
      },
      tableHeader: {
        bold: true,
        fontSize: 13,
        color: 'black'
      },
      jsonformat: {
        bold: false,
        fontSize: 13,
        color: 'black'
      }
    },
  }
  // ajustando o loading
  store.default.dispatch('setMessageDialog', "Criando o PDF");
  store.default.dispatch('setPctDialog', { actual: ++sAtual, total: steps });
  // gerando o pdf e o download
  pdfMake.createPdf(dd).download(`${filename}.pdf`);
  // console.log(`${filename}.pdf`);
  // pdfMake.createPdf(dd).open();
}

exports.eventosCamera = async (data, title, showImages, subtitle, filename) => {
  steps = 1 + data.length;
  sAtual = 0;
  if (!Array.isArray(data)) {
    return null
  }
  if (data.length < 1) {
    return null
  }

  let img = showImages ? showImages : false
  let body = [
    [
      {
        text: "Evento",
        style: "tableHeader",
        alignment: "center",
        colSpan: 2,
      },
      {},
      { text: "Data do Evento", style: "tableHeader", alignment: "center" },
      { text: "Data do Sincronismo", style: "tableHeader", alignment: "center" },
      { text: "Considerado", style: "tableHeader", alignment: "center" },
      { text: "Tratamento", style: "tableHeader", alignment: "center" },
      { text: "Data do Tratamento", style: "tableHeader", alignment: "center" },
      { text: "Tratado Por", style: "tableHeader", alignment: "center" },
      img ? { text: "Imagem", style: "tableHeader", alignment: "center" } : {},
    ],
  ];

  store.default.dispatch('setMessageDialog', "Gerando o Corpo do PDF");
  store.default.dispatch('setPctDialog', { actual: sAtual, total: steps });
  for (const key in data) {
    let imgs = null;
    if (Object.hasOwnProperty.call(data, key)) {
      const e = data[key];
      if (e.snapshot) {
        try {
          imgs = await CanvasDraw.drawImage(e, { width: 640, height: 480 });
        } catch (error) {
          imgs = null;
        }
      }
      body.push([
        { text: e.evtName || `Não Cadastrado ${e.code}`, alignment: "center" },
        { text: "", alignment: "center" },
        { text: moment(new Date(e.createdAt)).format('DD/MM/YYYY HH:mm:ss'), alignment: "center" },
        { text: moment(new Date(e.syncAt)).format('DD/MM/YYYY HH:mm:ss'), alignment: "center" },
        {
          text: e.tratamentoReal
            ? e.tratamentoReal
              ? "REAL"
              : "FALSO"
            : "", alignment: "center"
        },
        { text: e.tratamento, alignment: "center" },
        e.tratamentoCreatedAt ? { text: moment(new Date(e.tratamentoCreatedAt)).format('DD/MM/YYYY HH:mm:ss'), alignment: "center" } : {},
        { text: e.userName ? e.userName : e.monitoramentoName, alignment: "center" },
        img && imgs ? { image: imgs, width: 100 } : {},
      ]);
      store.default.dispatch('setPctDialog', { actual: ++sAtual, total: steps });
    }
  }

  let pdfDATA = await pdf(body, title, subtitle, null, filename);
  return pdfDATA
}

exports.eventosCameraLPR = async (data, title, showImages, subtitle, filename) => {
  steps = 1 + data.length;
  sAtual = 0;
  if (!Array.isArray(data)) {
    return null
  }
  if (data.length < 1) {
    return null
  }

  let img = showImages ? showImages : false
  let body = [
    [
      {
        text: "Evento",
        style: "tableHeader",
        alignment: "center",
      },
      { text: "Placa", style: "tableHeader", alignment: "center" },
      { text: "Velocidade", style: "tableHeader", alignment: "center" },
      { text: "Data do evento", style: "tableHeader", alignment: "center" },
      { text: "Metadados", style: "tableHeader", alignment: "center" },
      img ? { text: "Imagem", style: "tableHeader", alignment: "center" } : {},
    ],
  ];

  store.default.dispatch('setMessageDialog', "Gerando o Corpo do PDF");
  store.default.dispatch('setPctDialog', { actual: sAtual, total: steps });
  for (const key in data) {
    let imgs = null;
    if (Object.hasOwnProperty.call(data, key)) {
      const e = data[key];
      if (e.snapshot) {
        try {
          imgs = await CanvasDraw.drawImage(e, { width: 720, height: 480 });
        } catch (error) {
          imgs = null;
        }
      }
      let plate = "Não identificado";
      let speed = "Não identificado";
      let metadata = [];
      if (Array.isArray(e.detections) && e.detections.length > 0) {
        plate = e.detections.map(e => e.plate).join(' ');
        speed = e.detections.map(e => e.speed).join(' ');
        for (let i = 0; i < e.detections.length; i++) {
          metadata.push(['Marca', e.detections[i].VehicleSign]);
          metadata.push(['Tamanho', e.detections[i].VehicleSize]);
          metadata.push(['Cor', e.detections[i].VehicleColor]);
          metadata.push(['Cor Placa', e.detections[i].PlateColor]);
        }
      }
      body.push([
        { text: e.evtName || `Não Cadastrado ${e.code}`, alignment: "center" },
        { text: plate, alignment: "center" },
        { text: speed + "Km/h", alignment: "center" },
        { text: moment(new Date(e.createdAt)).format('DD/MM/YYYY HH:mm:ss'), alignment: "center" },
        { style: 'noBorders',
          table: {
            body: metadata
          }, alignment: "center", layout: {
            defaultBorder: false,
          } },
        img && imgs ? { image: imgs, width: 100 } : {},
      ]);
      store.default.dispatch('setPctDialog', { actual: ++sAtual, total: steps });
    }
  }

  let pdfDATA = await pdf(body, title, subtitle, null, filename);
  return pdfDATA
}

exports.eventosCameras = async (data, title, showImages, subtitle, filename) => {
  steps = 1 + data.length;
  sAtual = 0;
  if (!Array.isArray(data)) {
    return null
  }
  if (data.length < 1) {
    return null
  }

  let img = showImages ? showImages : false
  let body = [
    [
      {
        text: "Evento",
        style: "tableHeader",
        alignment: "center",
        colSpan: 2,
      },
      {},
      { text: "Cliente", style: "tableHeader", alignment: "center" },
      { text: "Camera", style: "tableHeader", alignment: "center" },
      { text: "Data do Evento", style: "tableHeader", alignment: "center" },
      { text: "Data do Sincronismo", style: "tableHeader", alignment: "center" },
      { text: "Considerado", style: "tableHeader", alignment: "center" },
      { text: "Tratamento", style: "tableHeader", alignment: "center" },
      { text: "Data do Tratamento", style: "tableHeader", alignment: "center" },
      { text: "Tratado Por", style: "tableHeader", alignment: "center" },
      img ? { text: "Imagem", style: "tableHeader", alignment: "center" } : {},
    ],
  ];

  store.default.dispatch('setMessageDialog', "Gerando o Corpo do PDF");
  store.default.dispatch('setPctDialog', { actual: sAtual, total: steps });
  for (const key in data) {
    let imgs = null;
    if (Object.hasOwnProperty.call(data, key)) {
      const e = data[key];
      if (e.snapshot) {
        try {
          imgs = await CanvasDraw.drawImage(e, { width: 640, height: 480 });
        } catch (error) {
          imgs = null;
        }
      }
      body.push([
        { text: e.evtName || `Não Cadastrado ${e.code}`, alignment: "center" },
        { text: "", alignment: "center" },
        { text: e.clientId + " - " + e.clientName, alignment: "center" },
        { text: e.cameraId + " - " + e.cameraName, alignment: "center" },
        { text: moment(new Date(e.createdAt)).format('DD/MM/YYYY HH:mm:ss'), alignment: "center" },
        { text: moment(new Date(e.syncAt)).format('DD/MM/YYYY HH:mm:ss'), alignment: "center" },
        {
          text: e.tratamentoReal
            ? e.tratamentoReal
              ? "REAL"
              : "FALSO"
            : "", alignment: "center"
        },
        { text: e.tratamento, alignment: "center" },
        e.tratamentoCreatedAt ? { text: moment(new Date(e.tratamentoCreatedAt)).format('DD/MM/YYYY HH:mm:ss'), alignment: "center" } : {},
        { text: e.userName ? e.userName : e.monitoramentoName, alignment: "center" },
        img && imgs ? { image: imgs, width: 100 } : {},
      ]);
      store.default.dispatch('setPctDialog', { actual: ++sAtual, total: steps });
    }
  }

  let pdfDATA = await pdf(body, title, subtitle, null, filename);
  return pdfDATA
}

exports.logSistema = async (data, title, showImages, subtitle, filename) => {
  steps = 1 + data.length;
  sAtual = 0;
  if (!Array.isArray(data)) {
    return null
  }
  if (data.length < 1) {
    return null
  }

  let body = [
    [
      {
        text: "Usuário",
        style: "tableHeader",
        alignment: "center",
      },
      { text: "Grupo", style: "tableHeader", alignment: "center" },
      { text: "Metodo", style: "tableHeader", alignment: "center" },
      { text: "Url", style: "tableHeader", alignment: "center" },
      { text: "Data", style: "tableHeader", alignment: "center" },
      { text: "Tipo", style: "tableHeader", alignment: "center" },
      { text: "Corpo", style: "tableHeader", alignment: "center" },
      { text: "Parametros", style: "tableHeader", alignment: "center" },
      { text: "Query", style: "tableHeader", alignment: "center" },
    ],
  ];

  store.default.dispatch('setMessageDialog', "Gerando o Corpo do PDF");
  store.default.dispatch('setPctDialog', { actual: sAtual, total: steps });
  for (const key in data) {
    if (Object.hasOwnProperty.call(data, key)) {
      const e = data[key];
      let linha = [
        { text: `${e.user_id} - ${e.userName}`, alignment: "center" },
        { text: `${e.groupId} - ${e.groupName}`, alignment: "center" },
        { text: e.method, alignment: "center" },
        { text: url(e.url), alignment: "center" },
        { text: moment(new Date(e.createdAt)).format('DD/MM/YYYY HH:mm:ss'), alignment: "center" },
        { text: e.type, alignment: "center" },
        explodeData(e.body).map(e => { return { text: `${e.key}: ${e.value}`, alignment: "center", fontSize: 8 } }),
        explodeData(e.params).map(e => { return { text: `${e.key}: ${e.value}`, alignment: "center", fontSize: 8 } }),
        explodeData(e.query).map(e => { return { text: `${e.key}: ${e.value}`, alignment: "center", fontSize: 8 } }),
      ];
      body.push(linha);
      store.default.dispatch('setPctDialog', { actual: ++sAtual, total: steps });
    }
  }

  let pdfDATA = await pdf(body, title, subtitle, null, filename);
  return pdfDATA
}

function explodeData(json) {
  let retorno = [];
  for (const key in json) {
    if (Object.hasOwnProperty.call(json, key)) {
      retorno.push({ key, value: json[key] });
    }
  }
  return retorno;
}